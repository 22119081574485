<template>
  <section v-if="!loading">
    <v-tabs v-model="tab" background-color="transparent" id="course-manager" show-arrows class="mt-2">
      <v-tab
        v-for="item in items"
        :key="item"
        class="text-capitalize poppins f14 fw600 secondary--text"
        active-class="secondary-1--text"
      >
        <span>
          {{ item }}
        </span>
        <!-- <v-icon size="22" color="danger-1" v-if="item === 'Course Info'" class="ml-2">
          {{ course.class_categories.length > 0 ? "" : "mdi-alert-circle" }}
        </v-icon> -->
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="background-color: transparent"  id="main-container" >
      <v-tab-item v-for="item in items" :key="item">
        <v-row v-if="item === 'Course Info'" >
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : activity_log.show ? 8 : 12">
            <AboutCourse 
              :course="course" 
              :activity_log="activity_log.show" 
              :classes="classes"
              :permission="course.my_permission.length > 0 ? course.my_permission[0].pivot.permission : 'owner'"/>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : activity_log ? 4 : 12">
            <CourseActivityLog :activity_log="activity_log.show" @close="closeActivityLog" :popup="activity_log.popup"/>
          </v-col>
        </v-row>
        <ModuleEditor v-if="item === 'Module Editor'" 
          :course="course" 
          :topicLoading="topicLoading"
          :subtopicLoading="subtopicLoading"
          :permission="course.my_permission.length > 0 ? course.my_permission[0].pivot.permission : 'owner'"
          @addAssessment="addAssessment"
          @viewAssessment="viewAssessment"
          @viewModuleEvaluation="viewModuleEvaluation"
          @viewCourseEvaluation="viewCourseEvaluation"/>
        <Synchronous v-if="item === 'Synchronous'" :course_id="course.id"
          :status="course.status"
          :permission="course.my_permission.length > 0 ? course.my_permission[0].pivot.permission : 'owner'"/>
        <Assessment
          v-if="item === 'Assessment'"
          :item="selectedAssessment"
          :status="course.status"
          :assessment_questions="assessment_questions.filter(item => item.id === selectedAssessment.id)[0]"
          :permission="course.my_permission.length > 0 ? course.my_permission[0].pivot.permission : 'owner'"
          @closeAssessment="closeAssessment"
        />
        <ModuleEvaluation
          v-if="item === 'Module Evaluation'"
          :evaluation="selectedModuleEvaluation"
          :status="course.status"
          :permission="course.my_permission.length > 0 ? course.my_permission[0].pivot.permission : 'owner'"
          :loading="loadingViewEvaluation"
        />
        <CourseEvaluation 
          v-if="item === 'Course Evaluation'"
          :evaluation="selectedCourseEvaluation"
          :status="course.status"
          :permission="course.my_permission.length > 0 ? course.my_permission[0].pivot.permission : 'owner'"
          :loading="loadingViewEvaluation"
        />
      </v-tab-item>
    </v-tabs-items>

    <div class="my-10 secondary--text poppins f12 font-italic text-capitalize">
      Saved {{ getUpdatedAt }} 
    </div>

    <v-dialog max-width="350" v-model="dialog" :persistent="submitting">
      <v-sheet class="border" v-if="!saved">
        <div class="" v-if="!submitting">
          <div class="text-center pa-5">
            <v-icon color="success" size="46">mdi-check-circle-outline</v-icon>
            <h4 class="poppins primary--text fw600 my-3">
              Your changes will be saved to drafts.
            </h4>
          </div>
          <v-divider />
          <div class="text-center py-2">
            <v-btn
              text
              class="f12 poppins text-capitalize mx-1"
              width="100"
              @click="dialog = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="f12 poppins text-capitalize mx-1"
              width="100"
              @click="save"
              >Confirm</v-btn
            >
          </div>
        </div>
        <div class="text-center pa-5" v-else>
          <v-progress-circular size="32" indeterminate color="green" />
          <h4 class="poppins primary--text fw600 my-3">Saving...</h4>
        </div>
      </v-sheet>
      <v-sheet class="border" v-else>
        <div class="">
          <div class="text-center pa-5">
            <v-icon color="success" size="46">mdi-check-circle</v-icon>
            <h4 class="poppins primary--text fw600 my-3">Saved to drafts.</h4>
          </div>
        </div>
      </v-sheet>
    </v-dialog>
  </section>
  <circular v-else />
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import items from '../../constants/tabs/course/coursemanager'
import AboutCourse from "../../components/teacher/course/AboutCourse.vue";
import ModuleEditor from "../../components/teacher/course/ModuleEditor.vue";
import Assessment from "../../components/teacher/assessment/Assessment.vue";
import Synchronous from "../../components/teacher/course/Synchronous.vue";
import CourseActivityLog from "../../components/teacher/course/CourseActivityLog.vue";
import CourseEvaluation from "../../components/teacher/course/CourseEvaluation.vue";
import ModuleEvaluation from "../../components/teacher/course/ModuleEvaluation.vue";

export default {
  components: {
    ModuleEditor,
    AboutCourse,
    Assessment,
    Synchronous,
    CourseActivityLog,
    ModuleEvaluation,
    CourseEvaluation
  },
  data: () => ({
    items: items,
    loading: true,
    dialog: false,
    saved: true,
    next: null,
    submitting: false,
    selectedAssessment: null,
    selectedModuleEvaluation: null,
    selectedCourseEvaluation: null,
    topicLoading: false,
    subtopicLoading: false,
    loadingSave: false,
    loadingViewEvaluation: false
  }),
  mounted(){
    if(!this.$route.query || !this.$route.query.tab || this.$route.query.tab === '3') {
        this.$router.replace({ query: { tab: 2, timestamp: Date.now() } });
    } else {
      this.$router.replace({ query: { tab: this.$route.query.tab, timestamp: Date.now()} });
    }

    if(this.items.includes('Assessment')) {
      this.items.pop()
    }

    this.showCourseAction(this.$route.params.id).then(() => {
      this.loading = false;
      this.getAssessmentsAction({
        course_id: this.course.id,
      });
    });

    this.getClassesAction();
  },
  computed: {
    ...mapState({
      tenant: (state) => state.tenant,
    }),

    ...mapState("instructor", {
      course: (state) => state.course,
      classes: (state) => state.classes,
      assessments: (state) => state.assessments,
      assessment_classes: (state) => state.assessment_classes,
      assessment_questions: (state) => state.assessment_questions,
      activity_log: (state) => state.activity_log
    }),
    getUpdatedAt(){
      return this.$dateFormat.calendar(this.course.updated_at)
    },
    tab: {
      get() {
        let tab = parseInt(this.$route.query.tab);
        return tab;
      },
      set(tab) {
        this.$router.replace({ query: { tab: tab, timestamp: Date.now() } });
      },
    },
  },
  watch:{
    assessments(val){
      if(this.selectedAssessment) {
        return this.selectedAssessment = {  ...this.selectedAssessment, ...this.assessments.filter(i => i.id == this.selectedAssessment.id)[0] }
      }
    }
  },
  created() {
    this.showCourseAction(this.$route.params.id).then(() => {
      this.loading = false;
      this.getAssessmentsAction({
        course_id: this.course.id,
      });
    });

    this.getClassesAction();

  },
  methods: {
    ...mapActions("instructor", [
      "showCourseAction",
      "getClassesAction",
      "getAssessmentsAction",
      "addAssessmentAction",
      "updateAssessmentAction",
      "showTaggedUsersAction",
      "getTagClassAssessmentAction"
    ]),

    ...mapMutations(["alertMutation"]),

    ...mapMutations('instructor', ["activityLogMutation", "evaluationQuestionMutation"]),

    closeActivityLog() {
      this.activityLogMutation(false)
    },

    save() {
      this.submitting = true;
      setTimeout(() => {
        this.saved = true;
        setTimeout(() => {
          this.next();
        }, 2000);
      }, 2000);
    },

    addAssessment(data) {
      if(data.is_topic){
        this.topicLoading = true
      } else {
        this.subtopicLoading =  true
      }

      this.addAssessmentAction({ 
        course_id: this.course.id,
        module_id: data.module_id,
        is_topic: data.is_topic,
        topic_or_sub_topic_id: data.topic_or_subtopic_id,
        title: data.title,
        type_of_assessment: 'graded_assessment'
       }).then(() => {
        this.topicLoading = false;
        this.subtopicLoading = false;
        this.alertMutation({ 
          show: true, 
          text: "Assessment Successfully Created!",
          type: "success" });
      });
    },

    saveAssessment() {
      this.loadingSave =  true
      if(this.selectedAssessment.max_attempt == null) {
        this.selectedAssessment.max_attempt = 1;
      }
      this.updateAssessmentAction(this.selectedAssessment).then(()=>{
        this.loadingSave = false
        this.alertMutation({ 
          show: true, 
          text: "Assessment Successfully Saved!",
          type: "success" });
      })
    },

    closeAssessment() {
      this.items.pop()
      this.selectedAssessment = null
      // this.tab = 1
      this.tab = this.items.findIndex((i) => i === 'Module Editor')
      this.alertMutation({ 
        show: true, 
        text: "Assessment Successfully Deleted!",
        type: "success" });
    },

    viewAssessment(data){
      if(this.items.length === 3) {
        this.items.push('Assessment')
      } else {
        this.items.splice(3, 1)
        this.items.push('Assessment')
      }

      this.selectedAssessment = { ...this.assessments.filter(i => i.id == data.id)[0], ...data }
      if(this.selectedAssessment.type_of_assessment === null) {
        this.selectedAssessment.type_of_assessment = 'graded_assessment'
      }
      this.tab = this.items.findIndex((i) => i === 'Assessment')
      // if(this.course.status === 'PUBLISH') {
        
      //   this.tab = 3
      // } else this.tab = 3
      this.$vuetify.goTo('#course-manager', { offset: 0})
      // console.log(document.getElementById("main-container").scrollTop)
      // document.getElementById("main-container").scrollTop = 0

      this.showTaggedUsersAction({
        course_id: this.selectedAssessment.course_id,
        id: this.selectedAssessment.id,
        state_classes: this.assessment_classes
      }).then(()=>{
        this.getTagClassAssessmentAction({
          id: this.selectedAssessment.id,
        })
      })
    },

    viewModuleEvaluation(evaluation) {
      if(this.items.length === 3) {
        this.items.push('Module Evaluation')
        this.tab = this.items.length-1
      } else {
        this.items.splice(3, 1)
        this.items.push('Module Evaluation')
        this.tab = 2
      }

      this.$vuetify.goTo('#course-manager', { offset: 0})

      this.loadingViewEvaluation = true

      this.$api.get(`instructor/module/evaluations/${evaluation.id}`).then( res => {
        this.selectedModuleEvaluation = res.data.data
        this.evaluationQuestionMutation(res.data.data.module_evaluation_questions)
        this.loadingViewEvaluation = false
      })
    },

    viewCourseEvaluation(evaluation) {
      if(this.items.length === 3) {
        this.items.push('Course Evaluation')
        this.tab = this.items.length-1
      } else {
        this.items.splice(3, 1)
        this.items.push('Course Evaluation')
        this.tab = 2
      }

      this.$vuetify.goTo('#course-manager', { offset: 0})

      this.loadingViewEvaluation = true

      this.$api.get(`instructor/course/evaluations/${evaluation.id}`).then( res => {
        this.selectedCourseEvaluation = res.data.data
        this.evaluationQuestionMutation(res.data.data.course_evaluation_questions)
        this.loadingViewEvaluation = false
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.saved) return next();
    this.dialog = true;
    this.next = next;
  },
};
</script>
