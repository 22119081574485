<template>
  <section>
    <v-dialog persistent v-model="dialog" max-width="650">
      <v-card class="rounded-lg">
        <v-card-title class="mb-3">
          <v-row>
            <v-col>
              <div>
                <div class="secondary--text primary-font fw600 f14">{{ data.hasOwnProperty('topic_id') ? 'SUBTOPIC' : 'TOPIC' }}</div>
                <h3 class="primary--text primary-font fw600">{{ data.title }}</h3>
                <!-- <FormTextFieldRequired 
                  v-if="update"
                  :value.sync="material.title"
                /> -->
              </div>
            </v-col>
            <v-col cols="1">
              <v-btn icon @click="close()">
                <v-icon size="22">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- <v-card flat v-if="data.learning_material">
            <vimeo v-if="data.learning_material.file_type == 'youtube' || data.learning_material.file_type == 'mp4'"
              :uri="data.learning_material.url"
              :type="data.learning_material.file_type"
            />
            <docx v-else :file="data.learning_material.url"/>
          </v-card> -->
          <v-card v-if="data.learning_material && !update" flat>
              <vimeo v-if="data.learning_material.file_type == 'youtube' || data.learning_material.file_type == 'mp4'"
                  :uri="data.learning_material.url"
                  :type="data.learning_material.file_type"
                  :data="data.learning_material.file_type"
                />   
              <div class="container-frame" v-else-if="data.learning_material.file_type == 's3_link' || data.learning_material.file_type == 'scorm'">
                <iframe 
                  id="material-frame"
                  v-if="data.learning_material.url.split('.').slice(-1)[0] === 'ppt' || data.learning_material.url.split('.').slice(-1)[0] === 'docx'"
                  :src="`https://view.officeapps.live.com/op/embed.aspx?src=${data.learning_material.url}`"
                  class="responsive-iframe"
                />
                <iframe 
                  id="material-frame"
                  v-else
                  :src="data.learning_material.url"
                  class="responsive-iframe"
                />
              </div>
              <docx v-else-if="data.learning_material.file_type !== 'youtube' || data.learning_material.file_type != 'mp4'" :file="data.learning_material.url"/> 
          </v-card>
        </v-card-text>
        <v-card-text v-if="!update" class="d-flex flex-row">
          <v-spacer />
          <v-btn
            text
            class="text-capitalize primary-font f14 fw500 no-space"
            color="primary"
            @click="update = true"
          >
            <v-icon left small>mdi-pencil-outline</v-icon>
            Update Material
          </v-btn>
        </v-card-text>
        <MaterialForm v-if="update" :item="material" :type="type" @close="close()" />
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import MaterialForm from "./MaterialForm.vue";
export default {
  props: ["dialog", "data", "type"],
  components: {
    MaterialForm,
  },
  data: () => ({
    update: false,
    material: null
  }),
  watch: {
    dialog(val){
      if(val){
        this.material = JSON.parse(JSON.stringify(this.data))
      }
    }
  },
  methods: {
    ...mapMutations(["playerMutation"]),

    close() {
      this.playerMutation({
        action: "stop",
        duration: 0,
      });
      
      this.update = false;
      this.$emit("close");
    },
  },
};
</script>

<style></style>
