<template>
  <v-form ref="form">
    <section id="choices">
      <div class="col-lg-6 row">
        <div class="col-12 col-sm-6">
          <FormLabel
            :label="'TYPE *'"
          />
          <v-select
            dense
            outlined
            hide-details
            :items="assessment_question_types"
            v-model="item.type_of_question"
            v-on:input="selectType($event)"
            class="general-custom-field secondary-1--text f14 fw600 roboto"
            :disabled="$isReadOnly.isReadOnly(status, permission)"
          />
        </div>
        <div class="col-12 col-sm-6">
          <FormLabel
            :label="'SCORE'"
          />
          <v-text-field
            dense
            outlined
            v-model="item.score"
            class="general-custom-field secondary-1--text f14 fw600 poppins"
            :disabled="$isReadOnly.isReadOnly(status, permission)"
            :rules="this.assessment_type==='graded_assessment' &&  [rules.required]"
          >
            <template v-slot:append>
              <div class="pt-1 f12 secondary--text fw400">pts</div>
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="col-lg-8" style="margin-top: -30px">
        <FormLabel
          :label="'ENTER YOUR QUESTION HERE'"
        />
        <editor
          class="tiny"
          :api-key="mce_key"
          v-model="item.question"
          :disabled="$isReadOnly.isReadOnly(status, permission)"
          :init="{
            height: 200,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            menubar: 'insert format',
          }"
        />
      </div>
      <div class="col-lg-6" v-if="item.type_of_question">
        <FormLabel
          :label="note()"
          class="text-uppercase"
        />
        <div class="d-flex align-end" v-if="!$isReadOnly.isReadOnly(status, permission)">
            <v-btn
              v-if="item.type_of_question == 'essay'"
              text
              small
              color="primary"
              class="text-capitalize roboto f14 fw500 no-space mb-1"
              @click="importDialog()"
            >
              <v-icon left>mdi-file-import-outline</v-icon>
              Import Rubric
            </v-btn>
            <v-btn
              text
              color="primary"
              class="text-capitalize roboto f14 fw600 no-space mb-0 pb-0"
              :class="
                item.type_of_question == 'true_or_false'
                  ? 'd-none'
                  : item.type_of_question == 'modified_true_or_false'
                  ? 'd-none'
                  : item.type_of_question == 'essay'
                  ? 'd-none'
                  : item.type_of_question == 'short_answer'
                  ? 'd-none'
                  : item.type_of_question == 'date_and_time'
                  ? 'd-none'
                  : item.type_of_question == 'linear_scale'
                  ? 'd-none'
                  : item.type_of_question == 'multiple_choice_grid'
                  ? 'd-none'
                  : item.type_of_question == 'checkbox_grid'
                  ? 'd-none'
                  : item.type_of_question == 'paragraph'
                  ? 'd-none'
                  : ''
              "
              @click="addChoices"
            >
              <v-icon left>mdi-plus</v-icon>
              {{
                item.type_of_question == "identification"
                  ? "Add Possible Answers"
                  : "Add Choices"
              }}
            </v-btn>
          <div
            class="mx-3 d-flex align-end"
            v-if="
              item.type_of_question == 'justified_multiple_choice' ||
              item.type_of_question == 'identification' ||
              item.type_of_question == 'modified_true_or_false' ||
              item.type_of_question == 'essay'
            "
          >
            <v-switch
              hide-details
              dense
              class="mb-1"
              inset
              color="success"
              v-model="item.require_file_attachment"
            />
            <div class="roboto f14 fw500 primary--text mb-2">Require upload file</div>
          </div>
        </div>
        <v-radio-group
          dense
          v-if="item.assessment_answer"
          v-model="item.assessment_answer.correct_answer"
          mandatory
          hide-details
          :disabled="$isReadOnly.isReadOnly(status, permission)"
        >
          <div
            class="d-flex align-center my-1"
            v-for="(choice, i) in item.assessment_answer.choices"
            :key="i"
          >
            <v-radio
              v-if="item.type_of_question != 'identification' && item.type_of_question != 'checkbox'"
              color="success"
              class="mt-2"
              :value="`choice_${i}`"
              hide-details
            />
            <v-checkbox
              v-else-if="item.type_of_question == 'checkbox'"
              color="success"
              class="mt-3"
              :value="`choice_${i}`"
              hide-details
            />
            <v-icon v-else color="success">mdi-check-circle</v-icon>
            <v-text-field
              :readonly="item.type_of_question == 'true_or_false'"
              outlined
              dense
              solo
              hide-details
              :placeholder="
                item.type_of_question == 'identification'
                  ? 'Possible answer'
                  : `Type your answer ${i + 1}`
              "
              flat
              required
              v-model="choice[`choice_${i}`]"
              v-on:change="handleAnswer($event, `choice_${i}`, i)"
              :class="item.type_of_question == 'true_or_false' ? 'f13' : 'f14'"
              class="general-custom-field secondary-1--text fw600 roboto mx-2 my-1"
            />
            <v-text-field
              v-if="item.type_of_question != 'checkbox' && item.type_of_question != 'dropdown'"
              outlined
              dense
              solo
              hide-details
              placeholder="Enter Feedback"
              flat
              v-model="choice.feedback"
              v-on:change="handleAnswer($event, 'feedback', i)"
              class="general-custom-field secondary-1--text f14 fw600 roboto mx-2 my-1"
            />
            <section v-if="item.assessment_answer.choices.length > 1">
              <v-btn icon @click="removeOption(i)" v-if="!$isReadOnly.isReadOnly(status, permission)">
                <v-icon>
                  mdi-close-circle
                </v-icon>
              </v-btn>
            </section>
          </div>
        </v-radio-group>
        <div v-if="item.type_of_question === 'linear_scale'">
          <section class="d-flex flex-wrap mb-2">
            <select
              class="input-sm mr-2"
            >
              <option
                v-for="(value, i) in min"
                :key="i"
                :value="value"
                class="roboto fw500 f12 secondary-3--text"
              >
                {{ value }}
              </option>
            </select>
            -
            <select
              class="input-sm ml-2"
            >
              <option
                v-for="(value, i) in max"
                :key="i"
                :value="value"
                class="roboto fw500 f12 secondary-3--text"
              >
                {{ value }}
              </option>
            </select>
          </section>
          <section class="d-flex flex-row align-center justify-space-between">
            <label class="poppins fw500 f12 secondary-2--text">0</label>
            <v-text-field
              outlined
              dense
              solo
              hide-details
              flat
              required
              class="f12 general-custom-field secondary-1--text fw600 roboto ml-4 mr-2 my-1"
            />
          </section>
          <section class="d-flex flex-row align-center justify-space-between">
            <label class="poppins fw500 f12 secondary-2--text">10</label>
            <v-text-field
              outlined
              dense
              solo
              hide-details
              flat
              required
              class="f12 general-custom-field secondary-1--text fw600 roboto mx-2 my-1"
            />
          </section>
        </div>
        <div v-if="item.type_of_question === 'multiple_choice_grid' || item.type_of_question === 'checkbox_grid'">
          <section class="d-flex">
            <section class="mx-2">
              <label class="poppins fw500 f12 secondary-2--text mb-2">ROWS</label>
              <v-text-field
                outlined
                dense
                solo
                hide-details
                flat
                required
                class="f12 general-custom-field secondary-1--text fw600 roboto my-1"
              />
              <v-text-field
                outlined
                dense
                solo
                hide-details
                flat
                required
                class="f12 general-custom-field secondary-1--text fw600 roboto my-1"
              />
              <v-text-field
                outlined
                dense
                solo
                hide-details
                flat
                required
                class="f12 general-custom-field secondary-1--text fw600 roboto my-1"
              />
              <v-btn
                text
                color="primary"
                class="roboto f12 fw600 no-space"
                >Add rows
              </v-btn>
            </section>
            <section class="mx-2">
              <label class="poppins fw500 f12 secondary-2--text mb-2">COLUMNS</label>
              <v-text-field
                outlined
                dense
                solo
                hide-details
                flat
                required
                class="f12 general-custom-field secondary-1--text fw600 roboto my-1"
              />
              <v-text-field
                outlined
                dense
                solo
                hide-details
                flat
                required
                class="f12 general-custom-field secondary-1--text fw600 roboto my-1"
              />
              <v-text-field
                outlined
                dense
                solo
                hide-details
                flat
                required
                class="f12 general-custom-field secondary-1--text fw600 roboto my-1"
              />
              <v-btn
                text
                color="primary"
                class="roboto f12 fw600 no-space"
                >Add columns
              </v-btn>
            </section>
          </section>
        </div>
      </div>
      <div class="col-12 mr-2" v-if="item.type_of_question == 'essay' && item.rubric_id && previewData == null" style="margin-top: -50px">
        <PreviewRubric :rubric=null mode="1" :rubric_id="item.rubric_id"/>
      </div>
      <div class="col-12 mr-2" v-else-if="item.type_of_question == 'essay' && previewData" style="margin-top: -50px">
        <PreviewRubric :rubric="previewData"  mode="2"/>
      </div>
      <div class="col-12" style="margin-top: -20px" v-if="!$isReadOnly.isReadOnly(status, permission)">
        <v-btn
          text
          color="primary"
          class="roboto f14 fw600 no-space"
          @click="save"
          :loading="loading"
          v-if="!saved"
          >Save Changes</v-btn
        >
        <v-btn text color="primary" class="roboto f14 fw600 no-space" v-else>
          <v-icon color="success" left>mdi-check-circle</v-icon>
          Saved</v-btn
        >
        <v-btn @click="cancel" text color="secondary" class="roboto f14 fw600 no-space"
          >Cancel</v-btn
        >
      </div>
      <ImportRubricDialog :dialog="dialog" @getRubricID="getRubricID" @close="dialogClose()" :data="rubrics" />
    </section>
  </v-form>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { assessment_question_types } from "@/shared/questionTypes.js";
import Editor from "@tinymce/tinymce-vue";
import ImportRubricDialog from "./ImportRubricDialog.vue";
import PreviewRubric from "../rubrics/PreviewRubric.vue";
export default {
  props: ["item", "assessment_type", "status", "permission"],
  components: {
    editor: Editor,
    ImportRubricDialog,
    PreviewRubric,
  },
  data: () => ({
    loading: false,
    saved: false,
    dialog: false,
    previewData: null,
    rubricID: null,
    rules: {
      required: value => !!value || 'Required.',
    },
    assessment_question_types,
    min: [ 0, 1 ],
    max: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ],
    mce_key: process.env.VUE_APP_TINYMCE_KEY
  }),
  mounted() {},
  computed: {
    ...mapState("instructor", {
      course: (state) => state.course,
      rubrics: (state) => state.rubrics
    }),
  },
  methods: {
    ...mapActions("instructor", [
      "updateQuestionAction",
      "getAssessmentsAction",
      "getRubricsAction",
    ]),
    ...mapMutations("instructor", ["addChoicesMutation", "inputAnswerMutation"]),
    selectType(e) {
      this.item.assessment_answer.choices = null;
      if (
        e == "multiple_choice" ||
        e == "justified_multiple_choice" ||
        e == "identification" ||
        e == "dropdown"
      ) {
        return (this.item.assessment_answer.choices = [
          { choice_0: e == "identification" ? "" : "Type your answer 1", feedback: "" },
          { choice_1: e == "identification" ? "" : "Type your answer 2", feedback: "" },
          { choice_2: e == "identification" ? "" : "Type your answer 3", feedback: "" },
        ]);
      }

      if (
        e == 'checkbox' 
      ) {
        return (this.item.assessment_answer.choices = [
          { choice_0: "Type your option 1", feedback: "" },
          { choice_1: "Type your option 2", feedback: "" },
          { choice_2: "Type your option 3", feedback: "" },
        ]);
      }

      if (e == "true_or_false" || e == "modified_true_or_false") {
        return (this.item.assessment_answer.choices = [
          { choice_0: "TRUE", feedback: "" },
          { choice_1: "FALSE", feedback: "" },
        ]);
      }
    },
    addChoices() {
      this.addChoicesMutation({
        assessment_id: this.item.assessment_id,
        question_id: this.item.id,
      });
    },
    getRubricID(id){
      this.rubricID = id
    },
    removeOption(i) {
      let choicesArr = []
      this.item.assessment_answer.choices.splice(i, 1);
      this.item.assessment_answer.choices.forEach((_choice, i) => {
        let _keys = Object.keys(_choice)
        let _new_choice = {
          [`choice_${i}`]: _choice[_keys[0]].includes('Type your answer') ? `Type your answer ${i+1}` : _choice[_keys[0]],
          feedback: _choice['feedback']
        }
        choicesArr.push(_new_choice)
        console.log(i)
      })
      console.log(choicesArr)
      this.item.assessment_answer.choices = choicesArr
    },
    cancel() {
      this.getAssessmentsAction({ course_id: this.course.id });
    },
    save() {
      if(this.$refs.form.validate()) {
        if(this.rubricID != null) {
          this.item.rubric_id = this.rubricID
        } 

        let question = this.item;
        // let choices = [];
        let answer = question.assessment_answer;
        // answer.choices.forEach((el) => {
        //   choices.push({
        //     answer: el.answer,
        //     feedback: el.feedback,
        //   });
        // });
        question["correct_answer"] = answer.correct_answer;
        question["choices"] = answer.choices;
        question["course_id"] = this.course.id

        this.loading = true;
        this.updateQuestionAction(question).then(() => {
          this.loading = false;
          this.saved = true;
          setTimeout(() => {
            this.saved = false;
          }, 2000);
        });
      }
    },

    handleAnswer(text, type, index) {
      // return console.log(type);
      this.inputAnswerMutation({
        assessment_id: this.item.assessment_id,
        question_id: this.item.id,
        type: type,
        text: text,
        index: index,
      });
    },

    note() {
      return this.item.type_of_question === "multiple_choice"
        ? "Enter multiple choices, then select the correct answer"
        : this.item.type_of_question === "justified_multiple_choice"
        ? "Enter multiple choices, then select the correct answer. The Student will type their explanation on a separate text box."
        : this.item.type_of_question === "modified_true_or_false"
        ? "Enter True or false. Student will type the correct answer on a separate text box."
        : this.item.type_of_question === "true_or_false"
        ? "Enter True or false, then select the correct answer"
        : this.item.type_of_question === "essay"
        ? "ESSAY. Student will type their answer on a text box."
        : (this.item.type_of_question === "dropdown" || this.item.type_of_question === "checkbox")
        ? "Enter choices."
        : this.item.type_of_question === "linear_scale"
        ? "Enter minimum and maximum values for scale, then their labels"
        : (this.item.type_of_question === 'multiple_choice_grid' || this.item.type_of_question === 'checkbox_grid')
        ? "Add questions in rows and answer choices in columns"
        : "";
    },

    importDialog() {
      this.getRubricsAction().then(() => {
        this.dialog = true;
      });
    },

    dialogClose() {
      this.dialog = false;
      this.previewData = this.rubrics;
    },
  },
};
</script>
