<template>
    <div class="d-flex align-center ml-7">
      <v-text-field
        dense
        outlined
        hide-details
        prepend-inner-icon="mdi-notebook-edit-outline"
        class="no-outline-custom-field roboto f14 fw500 secondary--text"
        v-model="item.title"
        readonly
      >
        <template v-slot:append>
          <v-menu offset-y transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" style="margin: -6px">
                <v-icon size="20"> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list dense class="custom-border">
              <v-list-item link @click="$emit('viewAssessment', { ...item, is_topic: true, topic_or_sub_topic_id: topic_or_subtopic_id, topic_or_subtopic_title: topic_or_subtopic_title })">
                <v-list-item-title class="roboto f14 d-flex align-center">
                  <v-icon size="22" class="mr-1">mdi-eye-outline</v-icon>
                  <div class="">View Assessment</div>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="!$isReadOnly.isReadOnly(status, permission)"
                link
                @click="remove(item)"
              >
                <v-list-item-title class="roboto f14 d-flex align-center">
                  <v-icon size="22" class="mr-1">mdi-delete-outline</v-icon>
                  <div class="">Delete Assessment</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-text-field>
      <delete-dialog
        :dialog="dialog"
        :id="item.id"
        :course_id="item.course_id"
        type="Assessment"
        store="instructor/deleteAssessmentAction"
        @close="dialog = false"
      />
    </div>
  </template>
  
  <script>
  import ViewMaterial from "./ViewMaterial.vue";
  export default {
    props: ["item", "topic_or_subtopic_id", "topic_or_subtopic_title", "status", "permission"],
    components: { ViewMaterial },
    data: () => ({
      dialog: false,
      itemToRemove: null,
    }),
    methods: {
      remove(val) {
        console.log(val);
        this.itemToRemove = val;
        this.dialog = true;
      },
    }
  };
  </script>
  