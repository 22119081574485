export const assessment_question_types = [
	{ text: 'Multiple Choice', value: 'multiple_choice' },
	{ text: 'True or False', value: 'true_or_false' },
	{ text: 'Identification', value: 'identification' },
	{ text: 'Essay', value: 'essay' },
	{ text: 'Justified Multiple Choice', value: 'justified_multiple_choice' },
	{ text: 'Modified True or False', value: 'modified_true_or_false' },
]

export const survey_question_types = [
	{ text: 'Multiple Choice', value: 'multiple_choice', icon: 'mdi-format-list-checkbox' },
	{ text: 'Checkboxes', value: 'checkbox', icon: 'mdi-checkbox-marked-outline' },
	{ text: 'File Upload', value: 'file_upload', icon: 'mdi-upload-outline' },
	{ text: 'Dropdown', value: 'dropdown', icon: 'mdi-arrow-down-drop-circle-outline' },
	{ text: 'Date', value: 'date', icon: 'mdi-calendar-blank' },
	{ text: 'Time', value: 'time', icon: 'mdi-clock-outline' },
	{ text: 'Linear Scale', value: 'linear_scale', icon: 'mdi-dots-horizontal' },
	{ text: 'Multiple Choice Grid', value: 'multiple_choice_grid', icon: 'mdi-dots-grid' },
	{ text: 'Checkbox grid', value: 'checkbox_grid', icon: 'mdi-checkbox-multiple-outline' },
	{ text: 'Short Answer', value: 'short_answer', icon: 'mdi-text-short' },
	{ text: 'Paragraph', value: 'paragraph', icon: 'mdi-text-long' },
]