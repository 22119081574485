<template>
  <v-sheet class="mr-3 my-2 custom-border border hidden-sm-and-down">
    <h5 class="poppins fw600 secondary-3--text px-3 py-2">
      Course Navigator
    </h5>
    <v-divider/>
    <div class="pa-2">
      <v-sheet v-for="item in modules" :key="item.id" class="px-3 py-2 my-2" color="secondary-5" rounded>
        <div class="poppins f12 fw600 secondary-3--text">
          {{item.title ? item.title : 'Untitled'}}
        </div>
        <div class="f10 poppins secondary-3--text" v-if="item.description !== ''">
          {{ item.description }}
        </div>
      </v-sheet>
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: ['modules']
}
</script>