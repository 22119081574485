<template>
  <v-sheet class="pa-5 mt-2 custom-border border">
    <div :class="$vuetify.breakpoint.mobile ? 'col-lg-12' : activity_log ? 'col-lg-9' : 'col-lg-6'" class="d-flex flex-wrap align-center py-0">
      <section class="d-flex flex-row align-center">
        <label class="caption ma-1 mr-3">COST:</label>
        <label class="caption ma-1">PHP</label>
        <v-text-field
          dense
          hide-details
          min="1"
          type="number"
          v-model="course.price"
          v-on:input="updatePrice"
          class="secondary-1--text f12 fw600 poppins col-7"
          :disabled="$isReadOnly.isReadOnlyNotCourseOwner(course.status, permission) || course.is_free">
        >
        </v-text-field>
      </section>
      <div class="d-flex align-center">
        <v-checkbox @change="updateIsFree" v-model="course.is_free" :disabled="$isReadOnly.isReadOnlyNotCourseOwner(course.status, permission)"/>
        <label class="caption">Mark this course as <b class="primary--text">FREE</b></label>
      </div>
    </div>
    <div :class="$vuetify.breakpoint.mobile ? 'col-lg-12' : activity_log ? 'col-lg-9' : 'col-lg-6'" class="d-flex flex-row align-center">
      <label class="caption ma-1 mr-3">TRAINING HOURS:</label>
      <input
        type="number"
        class="input-sm"
        style="width: 130px"
        v-model="setTrainingHours"
        v-on:input="updateTrainingHours($event)"
        :readonly="$isReadOnly.isReadOnly(course.status, permission)"
      />
      <div class="caption ma-1">hrs </div>
    </div>
    <div :class="$vuetify.breakpoint.mobile ? 'col-lg-12' : activity_log ? 'col-lg-9' : 'col-lg-6'" class="ma-1">
      <label class="caption">FACILITATOR :</label>
      <v-radio-group :disabled="$isReadOnly.isReadOnly(course.status, permission)" hide-details class="pt-0 mt-2" v-model="setFacilitator">
        <v-radio :value="true" dense>
          <template v-slot:label>
            <label class="caption">Assign myself as instructor</label>
          </template>
        </v-radio>
        <v-radio :value="false" dense>
          <template v-slot:label>
              <v-text-field 
                outlined
                dense
                class="general-custom-field roboto f14 fw500 secondary--text"
                :disabled="$isReadOnly.isReadOnly(course.status, permission)"
                v-model="facilitator"
                v-on:change="(e) => {
                  course.facilitator = e
                  updateCourse()
                }"
                hide-details
              />
          </template>
        </v-radio>
      </v-radio-group>
    </div>
    <div :class="$vuetify.breakpoint.mobile ? 'col-lg-12' : activity_log ? 'col-lg-9' : 'col-lg-6'">
      <label class="caption">COURSE DESCRIPTION *</label>
      <!-- <v-textarea 
        outlined
        dense
        class="general-custom-field roboto f14 fw500 secondary--text"
        :disabled="$isReadOnly.isReadOnly(course.status, permission)"
        v-model="course.description"
        v-on:input="setDescription"
        hide-details
      /> -->
      <editor
        class="tiny"
        v-model="course.description"
        v-on:input="setDescription"
        :api-key="mce_key"
        :disabled="$isReadOnly.isReadOnly(course.status, permission)"
        :init="{
          height: 200,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          invalid_elements: 'iframe,a,script'
        }"
      />
    </div>
    <div :class="$vuetify.breakpoint.mobile ? 'col-lg-12' : activity_log ? 'col-lg-9' : 'col-lg-6'">
      <label class="caption">CLASS TAGGING *</label>
      <v-row align="center">
        <v-col cols="12" sm="9">
          <v-select
            outlined
            dense
            class="general-custom-field roboto f14 secondary-1--text fw500"
            :items="classes"
            item-value="id"
            multiple
            v-model="course.class_categories"
            v-on:change="tagClass($event)"
            :disabled="$isReadOnly.isReadOnlyNotCourseOwner(course.status, permission)"
            hide-details
            :loading="loading"
          >
            <template slot="item" slot-scope="data">
              <v-list-item-content :name="data.item.class_name">
                <v-list-item-title class="roboto f14 fw500">
                  <span class="secondary--text">{{ data.item.class_code }}-</span>
                  <span class="secondary-3--text">{{ data.item.class_name }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template slot="selection" slot-scope="data">
              <div class="roboto f14 fw500 my-1">
                <span class="secondary--text">{{ data.item.class_code }}-</span>
                <span class="secondary-3--text">{{ data.item.class_name }},&nbsp; </span>
              </div>
            </template>
          </v-select>
        </v-col>
        <!-- <v-col cols="12" sm="2">
          <v-btn dense outlined color="primary" class="f12 poppins text-capitalize" @click="dialog = true">
            <v-icon left class="">mdi-eye-outline</v-icon>
            See enrollees
          </v-btn>
        </v-col> -->
      </v-row>
    </div>

    <div :class="$vuetify.breakpoint.mobile ? 'col-lg-12' : activity_log ? 'col-lg-9' : 'col-lg-6'">
      <label class="caption">COURSE AVAILABILITY</label>
      <div class="d-flex flex-wrap align-center mr-3 mb-3">
        <label class="caption mr-3">FROM:</label>
        <input
          type="date"
          class="input-sm"
          placeholder="mm/dd/yyyy"
          style="width: 130px"
          v-model="setStartDate"
          v-on:change="updateAvailabilityStart($event)"
          :readonly="$isReadOnly.isReadOnly(course.status, permission)"
        />
      </div>
      <div class="d-flex flex-wrap align-center">
        <label class="caption mr-3">UNTIL:</label>
        <input
          type="date"
          class="input-sm"
          placeholder="mm/dd/yyyy"
          style="width: 130px"
          v-model="setEndDate"
          v-on:change="updateAvailabilityEnd($event)"
          :readonly="$isReadOnly.isReadOnly(course.status, permission)"
        />
      </div>
    </div>

    <div :class="$vuetify.breakpoint.mobile ? 'col-lg-12' : activity_log ? 'col-lg-9' : 'col-lg-6'">
      <label class="caption" v-if="course.status === 'PUBLISH'">COURSE THUMBNAIL</label>
      <label class="caption" v-else>UPLOAD THUMBNAIL</label>
      <div v-if="course.status === 'PUBLISH'">
        <div v-if="!course.image" class="secondary-2--text poppins fw500 ma-1 f12"> No course thumbnail assigned</div>
        <div v-else>
          <v-img
            max-width="150"
            max-height="150"
            :src="course.image ? course.image.url : `${asset_path}/course-placeholder-1.png`"
            class="d-flex align-center text-center"
          />
        </div>
      </div>

      <circular v-if="loading_1" class="mx-auto my-3"/>
      
      <section class="d-flex flex-column align-center justify-center" v-else-if="course.image && course.status !== 'PUBLISH'">
        <!-- <v-btn icon class="ml-auto" @click="remove">
          <v-icon>
            mdi-close-circle
          </v-icon>
        </v-btn> -->
        <v-img
          max-width="150"
          max-height="150"
          :src="course.image ? course.image.url : `${asset_path}/course-placeholder-1.png`"
        />
        <a 
          @click="remove"
          class="caption error--text text-decoration-none"> Remove </a>
      </section>
      <div
        v-if="!$isReadOnly.isReadOnly(course.status, permission)" 
        class="bulk-border mt-2 py-3 d-flex flex-column align-center justify-center"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <div class="d-flex align-center">
          <v-icon color="rgba(63, 93, 208, 0.12)" size="22"
            >mdi-cloud-upload-outline</v-icon
          >
          <div class="secondary--text f12 mx-2">
            <a
              href="#"
              @click="$refs.image.click()"
              class="primary--text text-decoration-none"
            >
              Browse
            </a>
            or drag file here
          </div>
          <div class="secondary--text f12">( .jpg, .jpeg, .png )</div>
        </div>
      </div>
      <alert 
          v-if="msg.show"
          :show = "msg.show"
          :text = "msg.text"
          :type = "msg.type"
      />
    </div>
    <div v-if="$isReadOnly.isReadOnlyNotCourseOwnerPublishedUnpublished(course.status, permission)" class="d-flex flex-row align-center" :class="$vuetify.breakpoint.mobile ? 'col-lg-12' : activity_log ? 'col-lg-9' : 'col-lg-6'">
      <v-btn v-if="course.status !== 'DRAFT'" dense color="primary" class="poppins f12 mr-2" @click="share()">
        <v-icon size="18">mdi-share-outline</v-icon>
        Share
      </v-btn>
    </div>
    <div :class="$vuetify.breakpoint.mobile ? 'col-lg-12' : activity_log ? 'col-lg-9' : 'col-lg-6'">
      <div class="roboto text-wrap f10 secondary-2--text">Note: Sharing courses will allow your co-instructors to edit the whole course.</div>
    </div>
    <input
      type="file"
      ref="image"
      class="d-none"
      accept=".jpeg,.png,.jpg"
      v-on:change="accept"
    />
    <EnrolleeList 
      :dialog="dialog" 
      @close="dialog = false" 
      :count="getEnrolleeCount" 
      :enrollees="getEnrollees"
      :class_categories="course.class_categories"/>
    <ShareCourseDialog :dialog="share_dialog" @close="share_dialog = false" :course_id="course.id" :shared_to_users="course.shared_to_users"/>
  </v-sheet>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { mapActions, mapMutations, mapState } from "vuex";
import EnrolleeList from "./EnrolleeList.vue";
import ShareCourseDialog from "./ShareCourseDialog.vue";

export default {
  props: ["course", "classes", "activity_log", "permission"],
  data: () => ({
    show: false,
    dialog: false,
    form: new FormData(),
    asset_path: `${process.env.VUE_APP_ASSET_PATH}`,
    loading: false,
    loading_1: false,
    share_dialog: false,
    is_free: false,
    mce_key: process.env.VUE_APP_TINYMCE_KEY,
    facilitator: null,
    msg: {
      show: false,
      type: '',
      text: ''
    }
  }),
  components: {
    editor: Editor,
    EnrolleeList,
    ShareCourseDialog
},
  computed: {
    ...mapState({
      tenant: (state) => state.tenant
    }),

    setTrainingHours: {
      get() {
        if(this.course.training_hours === '' || this.course.training_hours > 0) {
          return this.course.training_hours
        } else {
          this.course.training_hours = 1
          return 1
        }
      },
      set(val) {
        this.course.training_hours = val
      }
      
    },

    setStartDate() {
      return this.course.available_until ? this.course.available_until.start_date ? this.course.available_until.start_date : null : null
    },

    setEndDate() {
      return this.course.available_until ? this.course.available_until.end_date ? this.course.available_until.end_date : null : null
    },

    setFacilitator: {
      get() {
        if( this.course.facilitator === null || this.course.facilitator.length === 0 ) {
          this.course.facilitator = null
          this.facilitator = null
          return true
        } else {
          this.facilitator = this.course.facilitator
          return false
        }
      },

      set(val){
        if(val) {
          this.course.facilitator = null
          this.facilitator = null
          this.updateCourse()
        } else {
          this.course.facilitator = this.facilitator
        }
      }
    },

    getEnrolleeCount() {
      return this.course.enrollments.length
      // let _count = 0
      // this.course.class_categories.forEach(_class => {
      //   _count += _class.enrollments.length
      // })
      // return _count
    },
    getEnrollees(){
      let _enrollees = []
      this.course.enrollments.forEach(item => {
        if(item.user) {
          _enrollees.push(
            {
              name: `${item.user.first_name ? this.$userFormat.name(item.user.first_name) : 'User'} ${item.user.last_name ? this.$userFormat.name(item.user.last_name) : 'Account'} ${item.user.suffix ? item.user.suffix : ''}`,
              date_enrolled: `${this.$dateFormat.mmDDyy(item.created_at)}`,
              course_id: item.course_id
            }
          )
        }
      })
      return _enrollees
    }
  },
  methods: {
    ...mapActions("instructor", ["showCourseAction", "getAssessmentsAction", "getClassesAction", "uploadThumbnailCourseAction", "tagClassCourseAction", "updateCourseDescriptionAction", "updateCourseUpdatedAt", "getInstructors", "removeCourseImage"]),

    ...mapMutations(['alertMutation']),

    dragover(e) {
      e.preventDefault();
      e.currentTarget.classList.add("bulk-border-drag");
    },
    dragleave(e) {
      e.preventDefault();
      e.currentTarget.classList.remove("bulk-border-drag");
    },

    drop(e) {
      e.preventDefault();
      this.$refs.image.files = e.dataTransfer.files;
      this.accept();
      e.currentTarget.classList.remove("bulk-border-drag");
    },
    accept() {
      this.msg = {
          show: false,
          type: '',
          text: ''
      }
      if(!['image/png', 'image/jpeg', 'image/jpg'].includes(this.$refs.image.files[0].type)) {
        this.msg = {
            show: true,
            type: 'error',
            text: `File not supported`
        }
        this.$refs.image.value = ''
      } else {
        const image = [...this.$refs.image.files];
        this.form.append("image_file", ...image);
        this.form.append("_method", 'PUT');

        this.loading_1 = true
        this.uploadThumbnailCourseAction({
          id: this.course.id,
          form: this.form,
        }).then(()=>{
          this.loading_1 = false
          this.alertMutation({
            show: true,
            text: 'Course successfully updated',
            type: "success"
          })
        }).catch(e => {
          this.loading_1 = false
        })
      }

    },

    tagClass(e) {
      this.loading = true
      this.tagClassCourseAction({
        id: this.course.id,
        class_category_id: e,
      }).then(()=>{
        this.showCourseAction(this.$route.params.id).then(() => {
          this.getAssessmentsAction({
            course_id: this.course.id,
          }).then(()=>{
            this.getClassesAction().then(()=>{
              this.loading = false
              this.alertMutation({
                show: true,
                text: 'Course successfully updated',
                type: "success"
              })
            })
          })
        });
      })
    },

    setDescription(e){
      this.updateCourseInfo({
        id: this.course.id,
        title: this.course.title,
        description: e,
        price: this.course.price,
        is_free: this.course.is_free,
        start_date: this.course.available_until ? this.course.available_until.start_date ? this.course.available_until.start_date : null : null,
        end_date: this.course.available_until ? this.course.available_until.end_date ? this.course.available_until.end_date : null : null,
        training_hours: this.course.training_hours,
        facilitator: this.course.facilitator
      })
    },

    updatePrice(e) {
      this.updateCourseInfo({
        id: this.course.id,
        title: this.course.title,
        description: this.course.description,
        price: parseInt(e) > 0 ? parseFloat(e) : null,
        is_free: this.course.is_free,
        start_date: this.course.available_until ? this.course.available_until.start_date ? this.course.available_until.start_date : null: null,
        end_date: this.course.available_until ? this.course.available_until.end_date ? this.course.available_until.end_date : null : null,
        training_hours: this.course.training_hours,
        facilitator: this.course.facilitator
      })
    },

    updateIsFree() {
      if(this.course.is_free) {
        this.course.price = null
      }

      this.updateCourseInfo({
        id: this.course.id,
        title: this.course.title,
        description: this.course.description,
        price: this.course.price,
        is_free: this.course.is_free,
        start_date: this.course.available_until ? this.course.available_until.start_date ? this.course.available_until.start_date : null : null,
        end_date: this.course.available_until ? this.course.available_until.end_date ? this.course.available_until.end_date : null : null,
        training_hours: this.course.training_hours,
        facilitator: this.course.facilitator
      })
    },

    updateAvailabilityStart(e){
      if(this.course.available_until) {
        if(!this.course.available_until.end_date) {
          this.course.available_until = {
            start_date: e.target.value,
            end_date: e.target.value
          }
        } else {
          if(e.target.value > this.course.available_until.end_date) {
            this.course.available_until = {
              start_date: e.target.value,
              end_date: e.target.value
            }
          } else {
            this.course.available_until = {
              start_date: e.target.value,
              end_date: this.course.available_until.end_date
            }
          }
        }
      } else {
        this.course.available_until = {
            start_date: e.target.value,
            end_date: e.target.value
          }
      }

      this.updateCourseInfo({
        id: this.course.id,
        title: this.course.title,
        description: this.course.description,
        price: this.course.price,
        is_free: this.course.is_free,
        start_date: e.target.value,
        end_date: this.course.available_until.end_date,
        training_hours: this.course.training_hours,
        facilitator: this.course.facilitator
      })
    },

    updateAvailabilityEnd(e){
      if(this.course.available_until) {
        if(!this.course.available_until.start_date) {
          this.course.available_until = {
            start_date: e.target.value,
            end_date: e.target.value
          }
        } else {
          if(e.target.value < this.course.available_until.start_date) {
            this.course.available_until = {
              start_date: e.target.value,
              end_date: e.target.value
            }
          } else {
            this.course.available_until = {
              start_date: this.course.available_until.start_date,
              end_date: e.target.value
            }
          }
        }
      } else {
          this.course.available_until = {
            start_date: e.target.value,
            end_date: e.target.value
          }
      }

      this.updateCourseInfo({
        id: this.course.id,
        title: this.course.title,
        description: this.course.description,
        price: this.course.price,
        is_free: this.course.is_free,
        start_date: this.course.available_until.start_date,
        end_date: e.target.value,
        training_hours: this.course.training_hours,
        facilitator: this.course.facilitator
      })
    },
    
    updateTrainingHours(e){
      if(e.target.value !== '') {
        this.updateCourseInfo({
          id: this.course.id,
          title: this.course.title,
          description: this.course.description,
          price: this.course.price,
          is_free: this.course.is_free,
          start_date: this.course.available_until ? this.course.available_until.start_date ? this.course.available_until.start_date : null: null,
          end_date: this.course.available_until ? this.course.available_until.end_date ? this.course.available_until.end_date : null : null,
          training_hours: e.target.value ? (e.target.value > 0 ? Math.ceil(e.target.value) : 1) : 1,
          facilitator: this.course.facilitator
        })
      }
    },

    updateCourse(){
      this.updateCourseInfo({
        id: this.course.id,
        title: this.course.title,
        description: this.course.description,
        price: this.course.price,
        is_free: this.course.is_free,
        start_date: this.course.available_until ? this.course.available_until.start_date ? this.course.available_until.start_date : null : null,
        end_date: this.course.available_until ? this.course.available_until.end_date ? this.course.available_until.end_date : null : null,
        training_hours: this.course.training_hours,
        facilitator: this.course.facilitator
      })
    },

    updateCourseInfo(info){
      this.updateCourseDescriptionAction(info).then(()=>{
        this.alertMutation({
          show: true,
          text: 'Course successfully updated',
          type: "success"
        })
      }).catch(() => {
        this.alertMutation({
          show: true,
          text: 'Something went wrong',
          type: "error"
        })
      })
    },

    share() {
      this.getInstructors().then(()=>{
        this.share_dialog =  true
      })
    },

    remove(){
      this.loading_1 = true
      this.removeCourseImage({ id: this.course.id }).then(()=>{
        this.course.image = null
        this.$refs.file.value = ''
        this.alertMutation({
          show: true,
          text: 'Course successfully updated',
          type: "success"
        })
        this.loading_1 = false
      }).catch(()=>{
        this.loading_1 = false
        this.alertMutation({
          show: true,
          text: 'Something went wrong',
          type: "error"
        })
      })
    }
  },

  watch: {
    facilitator(val) {
      if(val.length === 0) {
        this.facilitator = null
      }
    }
  }
};
</script>
